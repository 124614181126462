// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("D:\\git\\ash\\src\\templates\\index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-blog-post-js": () => import("D:\\git\\ash\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("D:\\git\\ash\\src\\templates\\tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("D:\\git\\ash\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("D:\\git\\ash\\src\\pages\\blog\\index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("D:\\git\\ash\\src\\pages\\contact\\examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("D:\\git\\ash\\src\\pages\\contact\\file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("D:\\git\\ash\\src\\pages\\contact\\index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("D:\\git\\ash\\src\\pages\\contact\\thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("D:\\git\\ash\\src\\pages\\tags\\index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

