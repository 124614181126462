module.exports = [{
      plugin: require('D:/git/ash/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('D:/git/ash/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"D:\\git\\ash/src/cms/cms.js"},
    },{
      plugin: require('D:/git/ash/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144512872-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"siteSpeedSampleRate":10,"cookieDomain":"stress-control.pl"},
    },{
      plugin: require('D:/git/ash/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
